<template>
  <v-list-item>
    <v-list-item-content>
      <v-row>
        <v-col class="col-sm-3">
          <b>{{ label }}:</b>
        </v-col>
        <v-col class="flex-column">
          <v-row>
            <v-col class="col-2">
              <GsActionsMenu
                :icon="actionIcon"
                :actions="actions"
                :onActivate="
                  actionId =>
                    this.onActivate({
                      actionId,
                      entityName: this.entityName,
                      uri: this.uri
                    })
                "
              />
            </v-col>
            <v-col>
              <v-row class="pt-lg-2 font-weight-black">{{ value }}</v-row>
              <v-row class="caption">
                {{ uri | empty }}
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { GsActionsMenu } from "ngt-frontend-core";
export default {
  name: "GsEntityLink",
  components: {
    GsActionsMenu
  },
  props: {
    label: {
      type: String,
      default: "entityName"
    },
    entityName: {
      type: String,
      default: "entityName"
    },
    value: {
      type: String,
      default: "value"
    },
    uri: {
      type: String
    },
    actionIcon: {
      type: String,
      default: null
    },
    actions: {
      type: Array,
      default() {
        return [
          "attributes",
          "tags",
          "profiles",
          "tokens",
          "identity_roles",
          "edit",
          "delete"
        ];
      }
    },
    onActivate: {
      type: Function,
      default() {
        console.log("on-activate missing!");
      }
    }
  },
  data() {
    return {
      scopes: [],
      loading: true
    };
  }
};
</script>
