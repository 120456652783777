<template>
  <v-container class="container" fluid>
    <v-card flat class="mb-3">
      <v-btn
        small
        class="elevation-4"
        color="primary"
        v-on:click="add_click()"
        >{{ $t("add_button") }}</v-btn
      >
      <!-- <GsResourceServerEditor
        ref="editor"
        :cancel="cancel_click"
        :save="save_click"
        v-if="status == statusValues.add || status == statusValues.edit"
      /> -->
    </v-card>
    <!-- filter panel -->
    <GsDataFilter
      ref="dataFilter"
      :appSetting="appSettingKey"
      :filters="filters"
      v-on:changed="onFilterChanged"
    />
    <!-- table -->
    <GsDataTable
      ref="table"
      api="idp_api_url"
      endpoint="/resource-servers"
      :appSetting="appSettingKey"
      :headers="headers"
      :default-sort-by="['resourceName']"
      :default-sort-desc="[false]"
      :beforeCallApi="beforeCallApi"
      :afterCallApi="afterCallApi"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="resourceServerId"
      show-expand
      @click:row="rowClicked"
      @after-refresh="afterRefresh"
    >
      <template v-slot:item.createdAt="{ item }">
        <span
          >{{ item.createdAt | getDisplayDate }} ({{
            item.createdBy.name | empty
          }})</span
        >
      </template>
      <template v-slot:item.lastLogin="{ item }">
        <span>{{ item.lastLogin | getDisplayDate }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <GsActionsMenu
          :actions="actions"
          :onActivate="actionId => action_activate(actionId, item)"
        />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <GsResourceServerDetails :resourceServer="item" />
        </td>
      </template>
    </GsDataTable>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
// import GsResourceServerEditor from "@/components/GsResourceServerEditor";
import GsResourceServerDetails from "@/components/GsResourceServerDetails";
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";

export default {
  name: "ResourceServers",
  components: {
    // GsResourceServerEditor,
    GsResourceServerDetails,
    GsDataTable,
    GsActionsMenu,
    GsDataFilter
  },
  props: {},
  data() {
    return {
      appSettingKey: "resourceServers",
      items: [],
      expanded: [],
      singleExpand: true,
      // filter: {
      //   resourceCode: null,
      //   resourceName: null,
      //   clusterCode: null
      // },
      initialized: false,
      actions: ["attributes", "tags", "edit", "generate_tokens"],
      // status: 0,
      // statusValues: {
      //   browse: 0,
      //   add: 1,
      //   edit: 2
      // },
      filters: [
        {
          field: "resourceCode",
          component: "GsTextFilter",
          params: {}
        },
        {
          field: "resourceName",
          component: "GsTextFilter",
          params: {}
        },
        {
          field: "clusterCode",
          component: "GsSelectFilter",
          params: {
            options: this.$store.state.lookups.clusterCodes
          }
        },
        {
          field: "resourceServerId",
          component: "GsIdFilter",
          params: {}
        }
      ]
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("actions"),
          value: "actions",
          width: "10%",
          sortable: false
        },
        {
          text: this.$t("resourceCode"),
          align: "left",
          value: "resourceCode",
          width: "20%",
          sortable: true
        },
        {
          text: this.$t("resourceName"),
          align: "left",
          value: "resourceName",
          width: "20%",
          sortable: true
        },
        {
          text: this.$t("resourceOrigin"),
          align: "left",
          value: "resourceOrigin",
          width: "20%",
          sortable: true
        },
        {
          text: this.$t("resourceServerId"),
          align: "left",
          value: "resourceServerId",
          width: "20%",
          sortable: true
        },
        {
          text: this.$t("clusterCode"),
          align: "left",
          value: "clusterCode",
          width: "20%",
          sortable: true
        },
        // {
        //   text: this.$t("identityId"),
        //   align: "left",
        //   value: "identityId",
        //   width: "10%",
        //   sortable: true
        // },
        {
          text: this.$t("identity"),
          align: "left",
          value: "identityId.name",
          width: "8%",
          sortable: false
        },
        {
          text: this.$t("lastLogin"),
          align: "left",
          value: "lastLogin",
          width: "10%",
          sortable: true
        },
        // { text: this.$t("_uri"), align: "left", value: "_uri", width: 150, sortable: true },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: "10%",
          sortable: true
        },
        { text: "", value: "data-table-expand" }
      ];
    }
  },
  mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      try {
        // console.log("refreshTable");
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += this.$refs.dataFilter.getFilter();
      const fields =
        "resourceName,resourceCode,clusterCode,resourceOrigin,logoUri,createdAt,updatedAt," +
        "lastLogin,scope,identityId,resourceClient,resourceScope," +
        "ownedBy,createdBy,updatedBy,resourceServerId,_uri";
      // const fields = "resourceName,resourceCode,clusterCode,resourceOrigin,createdAt,updatedAt,scope,identityId,ownedBy,createdBy,updatedBy,resourceServerId,_uri"
      params.url += `&fields=${fields}`;
      params.url +=
        "&r8sFields=createdBy.name,ownedBy.name,identityId.name,identityId.keyInfo," +
        "resourceScope.scopeName,resourceClient.client_name,resourceClient.identityId.name";
      return params;
    },
    async afterCallApi(params) {
      return params;
    },
    afterRefresh({ items }) {
      const expandedId = this.loadExpanded();
      if (expandedId) {
        const expandItem = items.find(p => p.resourceServerId == expandedId);
        if (expandItem) {
          this.expand(expandItem);
        }
      }
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async getResourceServer(resourceServerId) {
      const result = await callAPI({
        url: `${this.$store.state.idp_api_url}/resource-servers/${resourceServerId}`,
        method: "GET"
      });
      return result.data;
    },
    async add_click() {
      // this.status = this.statusValues.add;
      // this.expanded = [];
      // this.$nextTick(function () {
      //   // const initData = {
      //   //   resourceCode: "test3",
      //   //   resourceName: "testtype3",
      //   //   resourceOrigin: "https://ngt-id4all-res-test3.gsgroup.io",
      //   //   logoUri: "https://example.com/logo.png",
      //   //   scope: [],
      //   //   identityId: "https://ngt-id4all-res.gsgroup.io/identities/10078bdb-ab73-4681-b5f0-6189a0815b49"
      //   // };
      //   const initData = {
      //     resourceCode: null,
      //     resourceName: null,
      //     resourceOrigin: null,
      //     logoUri: null,
      //     scope: [],
      //     identityId: null
      //   };
      //   this.$refs.editor.add(initData);
      // });
      this.$router.push_safe(`/resource-servers/new`);
    },
    async edit_click(item) {
      // this.status = this.statusValues.edit;
      // this.expanded = [];
      // this.$nextTick(async function () {
      //   let initData = await this.getResourceServer(item.resourceServerId);
      //   if (typeof initData.scope === "undefined") initData.scope = [];
      //   this.$refs.editor.edit(initData);
      // });
      this.expand(item);
      this.$router.push_safe(`/resource-servers/${item.resourceServerId}`);
    },
    async attributes_click(item) {
      this.expand(item);
      this.$router.push_safe(
        `/resource-servers/${item.resourceServerId}/attrs`
      );
    },
    async tags_click(item) {
      this.expand(item);
      this.$router.push_safe(`/resource-servers/${item.resourceServerId}/tags`);
    },
    // async cancel_click() {
    //   this.status = this.statusValues.browse;
    // },
    // async save_click(sendData) {
    //   // console.log("sendData", sendData)
    //   let url = `${this.$store.state.idp_api_url}/resource-servers`;
    //   let method = "POST";
    //   if (sendData.resourceServerId) {
    //     url += `/${sendData.resourceServerId}`;
    //     method = "PATCH";
    //   }
    //   delete sendData.resourceServerId;
    //   delete sendData.createdBy;
    //   delete sendData.createdAt;
    //   delete sendData.updatedBy;
    //   delete sendData.updatedAt;
    //   delete sendData._uri;
    //   delete sendData.lastLogin

    //   if (sendData.logoUri == null || sendData.logoUri == "") {
    //     delete sendData.logoUri;
    //   }

    //   const data = sendData;
    //   try {
    //     await callAPI({ url, method, data });
    //   } catch (err) {
    //     console.log(err);
    //     this.validationResult = err;
    //   }
    //   this.status = this.statusValues.browse;
    //   await this.refreshTable();
    // },
    async delete_click(item) {
      alert("Not implemented!");
    },
    async generate_tokens(item) {
      return callAPI({
        url: `/resource-servers/${item.resourceServerId}/transactions`,
        method: "POST",
        data: {
          action: 'resourceServer.action.issue-token-pair',
        }
      });
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "attributes":
          await this.attributes_click(item);
          break;
        case "tags":
          await this.tags_click(item);
          break;
        case "edit":
          await this.edit_click(item);
          break;
        case "generate_tokens":
          console.log("generate_tokens", item);
          await this.generate_tokens(item);
          break;
      }
    },
    rowClicked(item) {
      const earlyReturn = this.expanded.some(
        p => p.resourceServerId === item.resourceServerId
      );
      if (earlyReturn) {
        this.expanded = [];
        this.saveExpanded(null);
        return;
      }
      this.expand(item);
    },
    expand(item) {
      if (!this.$refs.table) return;
      if (item) {
        this.expanded = [item];
        this.saveExpanded(item.resourceServerId);
        this.identity = item;
      }
    }
  }
};
</script>

<style lang="scss"></style>
