<template>
  <v-card>
    <v-card-title>Login status</v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="col-sm-3 font-weight-bold">
          {{ $t("lastLogin") }}:
        </v-col>
        <v-col class="col-1 mx-1">
          <v-icon :color="loginStatus.color">
            {{ loginStatus.icon }}
          </v-icon>
        </v-col>
        <v-col class="pl-0">
          {{ resourceServer.lastLogin | getDisplayDate }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="col-sm-3 font-weight-bold">
          {{ $t("dhKey") }}:
        </v-col>
        <v-col class="col-1 mx-1">
          <v-icon :color="dhKeyStatus.color">
            {{ dhKeyStatus.icon }}
          </v-icon>
        </v-col>
        <v-col class="pl-0">
          {{ resourceServer.identityId.keyInfo.dh.exp | getDisplayDate }}
        </v-col>
        <v-col v-if="dhKeyStatus.generate">
          <v-btn
            ref="dhButton"
            x-small
            color="accent"
            :disabled="!dhGenerateEnabled"
            @click="generateDhKey"
          >
            {{ $t("generate") }}
            <v-icon right>mdi-key-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="col-sm-3 font-weight-bold">
          {{ $t("dsaKey") }}:
        </v-col>
        <v-col class="col-1 mx-1">
          <v-icon :color="dsaKeyStatus.color">
            {{ dsaKeyStatus.icon }}
          </v-icon>
        </v-col>
        <v-col class="pl-0">
          {{ resourceServer.identityId.keyInfo.dsa.exp | getDisplayDate }}
        </v-col>
        <v-col v-if="dsaKeyStatus.generate">
          <v-btn
            ref="dsaButton"
            x-small
            color="accent"
            :disabled="!dsaGenerateEnabled"
            @click="generateDsaKey"
          >
            {{ $t("generate") }}
            <v-icon right>mdi-key-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
export default {
  name: "GsResourceServerLoginStatus",
  props: {
    resourceServer: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      dhGenerateEnabled: true,
      dsaGenerateEnabled: true,
    };
  },
  computed: {
    loginStatus() {
      if (!this.resourceServer.lastLogin) {
        return { color: "info", icon: "mdi-minus-circle-outline" };
      }

      const age =
        new Date().getTime() -
        new Date(parseInt(this.resourceServer.lastLogin, 10)).getTime();
      console.log("age:", age);
      if (age > 86400 * 1000) {
        return { color: "error", icon: "mdi-alert-circle" };
      }
      if (age > 600 * 1000) {
        return { color: "warning", icon: "mdi-alert-circle-outline" };
      }

      return {
        color: "success",
        icon: "mdi-check"
      };
    },
    dhKeyStatus() {
      console.log('dhKeyExp - resourceServer:', this.resourceServer);
      const statusIcon = {
        color: "error",
        icon: "mdi-key",
        generate: true,
      };

      if (this.resourceServer?.identityId?.keyInfo?.dh?.exp) {
        const timeLeft =
          new Date(parseInt(this.resourceServer.identityId.keyInfo.dh.exp, 10)).getTime() -
          new Date().getTime();

        if (timeLeft <= 0) {
          statusIcon.color = "error";
        } else if (timeLeft < 86400 * 30 * 1000) {
          statusIcon.color = "warning";
        } else {
          statusIcon.color = "success";
          statusIcon.generate = false;
        }
      }
      return statusIcon;
    },
    dsaKeyStatus() {
      console.log('dhKeyExp - resourceServer:', this.resourceServer);
      const statusIcon = {
        color: "error",
        icon: "mdi-key",
        generate: true,
      };

      if (this.resourceServer?.identityId?.keyInfo?.dsa?.exp) {
        const timeLeft =
          new Date(parseInt(this.resourceServer.identityId.keyInfo.dsa.exp, 10)).getTime() -
          new Date().getTime();

        if (timeLeft <= 0) {
          statusIcon.color = "error";
        } else if (timeLeft < 86400 * 30 * 1000) {
          statusIcon.color = "warning";
        } else {
          statusIcon.color = "success";
          statusIcon.generate = false;
        }
      }
      return statusIcon;
    },
  },
  methods: {
    async generateKey(keyType) {
      console.log(`generateKey: ${keyType} - resourceServer:`, this.resourceServer);
      this[`${keyType}GenerateEnabled`] = false;

      const identity = this.resourceServer.identityId;
      const { data } = await callAPI({
        url: `/identities/${identity.identityId}/keys`,
        method: "POST",
        data: {
          keyType,
          keySequenceId: identity[`${keyType}KeySequenceId`],
        },
      });
      console.log('key generation result:', data);
      if (data.keyId) {
        const queryString = `keyId=${data.keyId}&fields=exp`;
        const { data: newKey } = await callAPI({
          url: `/identities/${identity.identityId}/keys?${queryString}`,
          method: "GET",
        });
        console.log('newKey:', newKey);
        if (Array.isArray(newKey)) {
          this.resourceServer.identityId.keyInfo[keyType].exp = newKey[0].exp;
        }
      }
    },
    async generateDhKey() {
      return this.generateKey('dh');
    },
    generateDsaKey() {
      return this.generateKey('dsa');
    },
  }
};
</script>